import React from 'react';
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div>
            <div className="hero-banner-five">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-md-7">
                            <h1 className="hero-heading">Cutting-Edge Fintech <span>Solutions</span> </h1>
                            <p className="text-lg mb-50 pt-40 pe-xl-5 md-pt-30 md-mb-40">Empowering Business throught AI and Machine Learning.</p>
                        </div>
                    </div>
                </div>
                <div className="illustration-holder">
                    <img src="images/assets/ils_13.svg" alt="" className="main-illustration ms-auto"/>
                    <img src="images/assets/ils_13_1.svg" alt="" className="shapes shape-one"/>
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-two" data-aos="fade-down"/>
                    <img src="images/assets/ils_13_2.svg" alt="" className="shapes shape-three" data-aos="fade-down"/>
                </div>
                <div className="shapes oval-one"></div>
            </div>


            <div className="fancy-feature-seventeen position-relative mt-160 xl-mt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5" data-aos="fade-right">
                            <div className="title-style-three text-center text-lg-start">
                                <h2 className="main-title"><span>Services</span> We Provide with Quality.</h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7" data-aos="fade-left">
                            <p className="m0 text-center text-lg-start md-pt-30">SNSTEC offers cutting-edge products and services, including AI-driven credit risk assessment, advanced fraud detection, and personalized customer insights</p>
                        </div>
                    </div>

                    <div className="row justify-content-center pt-30">
                        <div className="col-lg-4 col-md-6" data-aos="fade-right">
                            <div className="block-style-twelve mt-30 ps-lg-0 pe-xl-5 me-xl-2">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_31.svg" alt=""/>
                                </div>
                                <h5><a>Data Science</a></h5>
                                <p>Transform financial data into actionable insights with AI-driven analytics for risk, fraud, and personalization</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                            <div className="block-style-twelve mt-30 active">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_32.svg" alt=""/>
                                </div>
                                <h5><a>Customer Support</a></h5>
                                <p>Expert support team ensures seamless integration, quick resolutions, and personalized assistance for client satisfaction</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" data-aos="fade-left">
                            <div className="block-style-twelve mt-30">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_33.svg" alt=""/>
                                </div>
                                <h5><a>Machine Learning</a></h5>
                                <p>Leverage AI to enhance credit risk, fraud detection, and customer insights with tailored machine learning.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shapes shape-one"></div>
            </div>


            <div
                className="fancy-feature-eighteen position-relative pt-200 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-three">

                                    <h2 className="main-title">Target <span>Audience</span> for your business.</h2>
                                </div>
                                <p className="pt-20 pb-25 lg-pb-20">AI technology provides the perfect solutions for optimizing business operations, and we are here to help you achieve your goals.</p>
                                <ul className="style-none list-item color-rev">
                                    <li>Advanced Data Analysis</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="illustration-holder" data-aos="fade-right">
                    <img src="images/assets/ils_14.svg" alt="" className="w-100 main-illustration"/>
                    <img src="images/assets/ils_14_1.svg" alt="" className="shapes shape-one" data-aos="fade-down"/>
                    <img src="images/assets/ils_14_2.svg" alt="" className="shapes shape-two" data-aos="fade-down"
                         data-aos-delay="100"/>
                    <img src="images/assets/ils_14_3.svg" alt="" className="shapes shape-three" data-aos="fade-down"
                         data-aos-delay="200"/>
                    <img src="images/assets/ils_14_4.svg" alt="" className="shapes shape-four"/>
                    <img src="images/assets/ils_14_5.svg" alt="" className="shapes shape-five"/>
                    <img src="images/assets/ils_14_6.svg" alt="" className="shapes shape-six"/>
                    <img src="images/assets/ils_14_7.svg" alt="" className="shapes shape-seven"/>
                </div>
                <div className="shapes oval-one"></div>
                <div className="shapes oval-two"></div>
                <div className="shapes oval-three"></div>
            </div>




            <div className="counter-section-one mt-140 lg-mt-100">
                <div className="container">
                    <div className="inner-container bg-color">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">20</span>mil</div>
                                    <p>Human labor hours saved <br/> with help of AI</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">1.3</span>b+</div>
                                    <p>Generated revenue by <br/>AI Solutions</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count">$<span className="counter">15</span>mil+</div>
                                    <p>Saved operational costs <br/>due to AI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                                <div className="title-style-three">
                                    <div className="sc-title">Contact us</div>
                                    <h2 className="main-title">Get Ready to Started. It’s Fast & Easy.</h2>
                                </div>
                                <p className="pt-20 pb-15">Reach out to us today. Our team is ready to assist you quickly and efficiently.</p>
                                <Link to="/contact" className="btn-eight ripple-btn" role="button">Send Message</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="illustration-holder" data-aos="fade-right">
                    <img src="images/assets/ils_16.svg" alt="" className="w-100 main-illustration"/>
                </div>
                <img src="images/shape/shape_37.svg" alt="" className="shapes shape-one"/>
                <div className="shapes oval-one"></div>
                <div className="shapes oval-two"></div>
            </div>

        </div>
    );
};

export default Home;