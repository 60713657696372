import React from 'react';

const TC = () => {
    return (
        <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-7 ms-auto">
                        <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                            <div className="title-style-three">
                                <div className="sc-title">Terms and Conditions</div>
                            </div>
                            <p className="pt-20 pb-15">Welcome to SNSTEC. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>
                            <h2 className="main-title">1. Agreement to Terms</h2>
                            <p className="pt-20 pb-15">These Terms and Conditions constitute a legally binding agreement between you and SNSTEC. By accessing our site, you confirm that you have read, understood, and agree to be bound by these terms. If you do not agree, please do not use our site.</p>
                            <h2 className="main-title">2. Intellectual Property Rights</h2>
                            <p className="pt-20 pb-15">All content, trademarks, and data on this site, including but not limited to software, databases, text, graphics, icons, and hyperlinks, are the property of or licensed to SNSTEC. You may not reproduce, distribute, or otherwise use any of the materials without our prior written consent.</p>
                            <h2 className="main-title">3. User Obligations</h2>
                            <p className="pt-20 pb-15">You agree not to use the site for any unlawful purpose or in any way that could damage, disable, overburden, or impair the site. You also agree not to interfere with any other party's use and enjoyment of the site.</p>
                            <h2 className="main-title">4. Limitation of Liability</h2>
                            <p className="pt-20 pb-15">SNSTEC will not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the site or inability to use the site, even if we have been advised of the possibility of such damages.</p>
                            <h2 className="main-title">5. Governing Law</h2>
                            <p className="pt-20 pb-15">These terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. You agree to submit to the exclusive jurisdiction of the courts located within [Your Jurisdiction] for the resolution of any disputes.</p>
                            <h2 className="main-title">6. Changes to Terms</h2>
                            <p className="pt-20 pb-15">We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting on the site. Your continued use of the site following the posting of changes constitutes your acceptance of such changes.</p>
                            <h2 className="main-title">7. Contact Information</h2>
                            <p className="pt-20 pb-15">For any questions or concerns about these terms, please contact us at tc@snstec.com or 35967496.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TC;