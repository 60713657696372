import React from 'react';

const Ai = () => {
    return (
        <div>

            <div className="hero-banner-two">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-7">
                                <h1 className="hero-heading">I’m Gorgia. Magical AI for solution.</h1>
                                <p className="text-lg pt-25 pb-40 lg-pb-20 sm-pt-10">Sinco delivered blazing fast,
                                    striking ai solution</p>
                                <ul className="style-none button-group d-sm-flex align-items-center">
                                    <li className="me-4 mt-10"><a  href="#"
                                                                  className="btn-one ripple-btn">Explore</a></li>
                                    <li><a className="demo-btn tran3s mt-10"  href="#">Request a demo</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="illustration-holder">
                        <img src="images/assets/ils_04.svg" alt="" className="main-illustration w-100"/>
                        <img src="images/assets/ils_04_1.svg" alt="" className="shapes shape-one"/>
                        <img src="images/assets/ils_04_2.svg" alt="" className="shapes shape-two"/>
                        <img src="images/assets/ils_04_2.svg" alt="" className="shapes shape-three"/>
                        <img src="images/assets/ils_04_3.svg" alt="" className="shapes shape-four"/>
                        <img src="images/assets/ils_04_4.svg" alt="" className="shapes shape-five"/>
                        <img src="images/assets/ils_04_5.svg" alt="" className="shapes shape-six"/>
                        <img src="images/assets/ils_04_6.svg" alt="" className="shapes shape-seven"/>
                        <div className="card-one shapes d-flex align-items-center justify-content-center">
                            <div className="icon"><i className="bi bi-check-lg"></i></div>
                            <h6>A++ Performance</h6>
                        </div>

                        <div className="card-two shapes text-center">
                            <div className="icon"><i className="bi bi-check-lg"></i></div>
                            <div className="main-count"><span className="counter">20</span>k</div>
                            <div className="info">5 Start Rating</div>
                            <ul className="style-none d-flex justify-content-center rating">
                                <li><i className="bi bi-star-fill"></i></li>
                                <li><i className="bi bi-star-fill"></i></li>
                                <li><i className="bi bi-star-fill"></i></li>
                                <li><i className="bi bi-star-fill"></i></li>
                                <li><i className="bi bi-star-fill"></i></li>
                            </ul>
                        </div>

                    </div>

                </div>

            </div>


            <div className="counter-section-one fancy-bg">
                <div className="container">
                    <div className="inner-container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">20</span>mil</div>
                                    <p>Human labor hours saved <br/> with help of AI</p>
                                </div>

                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">1.3</span>b+</div>
                                    <p>Generated revenue by <br/>AI Solutions</p>
                                </div>

                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count">$<span className="counter">15</span>mil+</div>
                                    <p>Saved operational costs <br/>due to AI</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <img src="images/shape/shape_04.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_05.svg" alt="" className="shapes shape-two"/>
            </div>


            <div className="partner-section-two mt-110">
                <div className="container">
                    <div className="title-style-one text-center" data-aos="fade-up">
                        <div className="sc-title-three">over 150k+ client</div>
                        <h2 className="main-title md">Join 27,000+ companies who’ve reached </h2>
                    </div>


                    <div className="row">
                        <div className="col-12 m-auto">
                            <ul className="style-none text-center">
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#"
                                                                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-13.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="100"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-12.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="200"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-9.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="300"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-7.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="400"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-8.png" alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>



            <div className="fancy-feature-four position-relative mt-110 lg-mt-50">
                <div className="bg-wrapper">
                    <div className="inner-container">
                        <div className="container">
                            <div className="row gx-xxl-5">
                                <div className="col-lg-4 mt-40 d-flex" data-aos="fade-up">
                                    <div className="d-flex justify-content-center flex-column">
                                        <div className="title-style-one">
                                            <div className="sc-title-three">Services</div>
                                            <h2 className="main-title">Our experties field.</h2>
                                        </div>

                                        <div>
                                            <div className="btn-three rev mt-35 lg-mt-20">For more details. <a
                                                 href="#">Click here <i
                                                className="fas fa-chevron-right"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="100">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img
                                            src="images/icon/icon_08.svg" alt=""/></div>
                                        <a  href="#"><h5>Predictive Analytics</h5></a>
                                        <p>Convert data noise to intelligent insights for competitive
                                            differentiation.</p>
                                        <a  href="#" className="more-btn"><img
                                            src="images/icon/icon_13.svg" alt="" className="tran3s"/></a>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="200">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img
                                            src="images/icon/icon_09.svg" alt=""/></div>
                                        <a  href="#"><h5>Data Engineers</h5></a>
                                        <p>Stay ahead of disruption & exceed customer expectation by implementing
                                            predictive analytics solutions.</p>
                                        <a  href="#" className="more-btn"><img
                                            src="images/icon/icon_13.svg" alt="" className="tran3s"/></a>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="100">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img
                                            src="images/icon/icon_10.svg" alt=""/></div>
                                        <a  href="#"><h5>Deep Learning</h5></a>
                                        <p>Access tools for deep learning, cloud computing and any scale.</p>
                                        <a  href="#" className="more-btn"><img
                                            src="images/icon/icon_13.svg" alt="" className="tran3s"/></a>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-sm-6 mt-40 d-flex" data-aos="fade-up" data-aos-delay="200">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img
                                            src="images/icon/icon_11.svg" alt=""/></div>
                                        <a  href="#"><h5>Data Mining</h5></a>
                                        <p>You can discover hidden opportunities collecting, analyzing or explaining
                                            your data in a different way.</p>
                                        <a  href="#" className="more-btn"><img
                                            src="images/icon/icon_13.svg" alt="" className="tran3s"/></a>
                                    </div>

                                </div>
                                <div className="col-lg-4 mt-40 d-flex" data-aos="fade-up" data-aos-delay="300">
                                    <div className="block-style-four">
                                        <div className="icon d-flex align-items-end justify-content-center"><img
                                            src="images/icon/icon_12.svg" alt=""/></div>
                                        <a  href="#"><h5>Statistical Modeling</h5></a>
                                        <p>Offer future-ready business applications that can learn & adjust with
                                            time.</p>
                                        <a  href="#" className="more-btn"><img
                                            src="images/icon/icon_13.svg" alt="" className="tran3s"/></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <img src="images/shape/shape_06.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_07.svg" alt="" className="shapes shape-two"/>
            </div>



            <div className="fancy-feature-two position-relative mt-200 lg-mt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-one">
                                    <div className="sc-title-three">Over 150k+ Client</div>
                                    <h2 className="main-title">World best AI for your business. </h2>
                                </div>

                                <p className="pt-10 pb-20 lg-pb-10">AI technology is perfect for best business solutions
                                    & we offer help to achieve your goals.</p>
                                <ul className="style-none list-item color-rev">
                                    <li>Various analysis options.</li>
                                    <li>Page Load (time, size, number of requests).</li>
                                    <li>Big data analysis.</li>
                                    <li>Advance Data analysis operation.</li>
                                </ul>
                                <div className="btn-three rev mt-55 lg-mt-30">Want to learn more about us? <a
                                     href="#">Click here <i className="fas fa-chevron-right"></i></a></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="illustration-holder-two sm-mt-40">
                    <img src="images/assets/ils_05.svg" alt="" className="main-illustration w-100"/>
                    <img src="images/assets/ils_05_1.svg" alt="" className="shapes shape-one"/>
                    <img src="images/assets/ils_05_2.svg" alt="" className="shapes shape-two" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="2000"/>
                    <img src="images/assets/ils_05_3.svg" alt="" className="shapes shape-three" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="150" data-aos-duration="2000"/>
                    <img src="images/assets/ils_05_4.svg" alt="" className="shapes shape-four"/>
                    <img src="images/assets/ils_05_5.svg" alt="" className="shapes shape-five"/>
                </div>

            </div>


            <div className="feedback-section-two mt-170 xl-mt-100 md-mt-60" data-aos="fade-up">
                <img src="images/shape/shape_08.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_09.svg" alt="" className="shapes shape-two"/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4 col-md-5 col-sm-8">
                            <div className="title-style-one text-center text-sm-start xs-pb-20">
                                <h2 className="main-title">Client’s success Stories.</h2>
                            </div>

                        </div>
                        <div
                            className="col-xl-6 col-lg-8 col-md-7 col-sm-4 ms-auto d-flex justify-content-center justify-content-sm-end">
                            <ul className="slider-arrows slick-arrow-two d-flex style-none">
                                <li className="prev_f1 slick-arrow ripple-btn"  ><i
                                    className="bi bi-arrow-left"></i></li>
                                <li className="next_f1 slick-arrow ripple-btn" ><i
                                    className="bi bi-arrow-right"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="inner-content mt-60 md-mt-40">
                    <div className="slider-wrapper">
                        <div className="feedback_slider_two">
                            <div className="item">
                                <div className="feedback-block-two d-sm-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_01.jpg" alt=""/>
                                        <a className="fancybox video-icon" data-fancybox=""
                                           href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                            <i className="bi bi-play-fill"></i>
                                        </a>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex align-items-end"><img src="images/logo/Plogo-15.png"
                                                                                          alt=""/></div>
                                        <div className="camp-name">Giant cloud service inc</div>
                                        <p>We’v 9,000 agents across around the country, Find agents near your
                                            neighborhood.</p>
                                        <a href="#"
                                           className="read-btn d-flex align-items-center justify-content-between">
                                            <span>Continue Reading</span>
                                            <img src="images/icon/icon_14.svg" alt="" className="arrow"/>
                                        </a>
                                    </div>

                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-two d-sm-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_02.jpg" alt=""/>
                                        <a className="fancybox video-icon" data-fancybox=""
                                           href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                            <i className="bi bi-play-fill"></i>
                                        </a>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex align-items-end"><img src="images/logo/Plogo-4.png"
                                                                                          alt=""/></div>
                                        <div className="camp-name">UK Marketing Agency.</div>
                                        <p>We’v 9,000 agents across around the country, Find agents near your
                                            neighborhood.</p>
                                        <a href="#"
                                           className="read-btn d-flex align-items-center justify-content-between">
                                            <span>Continue Reading</span>
                                            <img src="images/icon/icon_14.svg" alt="" className="arrow"/>
                                        </a>
                                    </div>

                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-two d-sm-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_03.jpg" alt=""/>
                                        <a className="fancybox video-icon" data-fancybox=""
                                           href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                            <i className="bi bi-play-fill"></i>
                                        </a>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex align-items-end"><img src="images/logo/Plogo-3.png"
                                                                                          alt=""/></div>
                                        <div className="camp-name">Netflix Marketing Agency.</div>
                                        <p>We’v 9,000 agents across around the country, Find agents near your
                                            neighborhood.</p>
                                        <a href="#"
                                           className="read-btn d-flex align-items-center justify-content-between">
                                            <span>Continue Reading</span>
                                            <img src="images/icon/icon_14.svg" alt="" className="arrow"/>
                                        </a>
                                    </div>

                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-two d-sm-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_04.jpg" alt=""/>
                                        <a className="fancybox video-icon" data-fancybox=""
                                           href="https://www.youtube.com/embed/aXFSJTjVjw0">
                                            <i className="bi bi-play-fill"></i>
                                        </a>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex align-items-end"><img src="images/logo/Plogo-2.png"
                                                                                          alt=""/></div>
                                        <div className="camp-name">Sinco Digital Agency.</div>
                                        <p>We’v 9,000 agents across around the country, Find agents near your
                                            neighborhood.</p>
                                        <a href="#"
                                           className="read-btn d-flex align-items-center justify-content-between">
                                            <span>Continue Reading</span>
                                            <img src="images/icon/icon_14.svg" alt="" className="arrow"/>
                                        </a>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>


            <div className="fancy-feature-five position-relative mt-50 xs-mt-20">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xxl-4 col-lg-5 col-md-6">
                            <div className="block-style-five pt-60 md-pt-20" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title-three">BESt AI SOLUTION</div>
                                    <h2 className="main-title">Why you should choose us?</h2>
                                </div>

                                <p className="pt-10 pb-70">Tell us about your product and we will give you expert
                                    assistance.</p>
                                <div className="skills-progress skills">
                                    <div className="habilidades_contenedor">
                                        <div className="codeconSkills">
                                            <div className="codeconSkillbar mb-80 md-mb-60">
                                                <div className="skill-text">
                                                    <span className="codeconSkillArea">SKIll</span>
                                                </div>
                                                <div className="skillBar" data-percent="73%"
                                                     style={{background: "#F25DFF"}}>
                                                    <span className="PercentText">73%</span>
                                                </div>
                                            </div>
                                            <div className="codeconSkillbar">
                                                <div className="skill-text">
                                                    <span className="codeconSkillArea ">Performance</span>

                                                </div>
                                                <div className="skillBar" data-percent="91%"
                                                     style={{background: "#00E8FA"}}>
                                                    <span className="PercentText">91%</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <a  href="#" className="btn-five ripple-btn mt-60 lg-mt-50">Learn More</a>
                            </div>

                        </div>

                        <div className="col-xxl-8 col-lg-7 col-md-6 text-end">
                            <div className="illustration-holder d-inline-block position-relative xs-mt-20">
                                <img src="images/assets/ils_06.svg" alt="" className="main-illustration w-100"/>
                                <img src="images/assets/ils_06_1.svg" alt="" className="shapes shape-one"/>
                                <img src="images/assets/ils_06_2.svg" alt="" className="shapes shape-two"/>
                                <img src="images/assets/ils_06_3.svg" alt="" className="shapes shape-three"
                                     data-aos="fade-down" data-aos-duration="1800"/>
                                <img src="images/assets/ils_06_4.svg" alt="" className="shapes shape-four"
                                     data-aos="fade-left" data-aos-duration="1800"/>
                                <img src="images/assets/ils_06_5.svg" alt="" className="shapes shape-five"/>
                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div className="fancy-feature-six position-relative mt-80">
                <div className="container">
                    <div className="bg-wrapper">
                        <div className="row gx-xxl-5 justify-content-center">
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{background: "rgba(68, 109, 255, 0.07)",color: "#446DFE"}}>
                                        <i className="bi bi-check-lg"></i></div>
                                    <h6>Qulaity Support</h6>
                                    <p>Learn content by interacting anything expert lesson a video.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{background: "rgba(253, 232, 255, 1)",color: "#F36EFF"}}><i
                                        className="bi bi-check-lg"></i></div>
                                    <h6>Fastest AI Machine</h6>
                                    <p>Practice what you realistic test great questions lorem.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="block-style-six text-center mt-40">
                                    <div className="icon" style={{background: "rgba(255, 122, 65, 0.12)",color: "#FF7A41"}}>
                                        <i className="bi bi-check-lg"></i></div>
                                    <h6>Great Pricing</h6>
                                    <p>Review your practice & learn how to improve your skill.</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <img src="images/shape/shape_10.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_11.svg" alt="" className="shapes shape-two"/>
            </div>


            <div className="feedback-section-three position-relative mt-250 lg-mt-130" data-aos="fade-up">
                <div className="container">
                    <div className="slider-wrapper">
                        <div className="feedback_slider_three">
                            <div className="item">
                                <div className="feedback-block-three d-md-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_05.jpg" alt=""/>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex justify-content-center align-items-center"><img
                                            src="images/icon/icon_15.svg" alt=""/></div>
                                        <p>Certainly from my perspective been great success with lore giving that <a
                                            href="#">enterprises</a> level magna assured quality due issue there live
                                            the blind texts separated.</p>
                                        <div className="name">
                                            <h6>Martin Jonas</h6>
                                            <span>Head of marketing, Inter inc.</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-three d-md-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_06.jpg" alt=""/>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex justify-content-center align-items-center"><img
                                            src="images/icon/icon_15.svg" alt=""/></div>
                                        <p>Having a home based business is a wonderful asset to your life. The problem
                                            still stands it comes time <a href="#">advertise</a> your business for a
                                            cheap cost you have looked.</p>
                                        <div className="name">
                                            <h6>Carolyn Tyler</h6>
                                            <span>Senior Developer, Sinco inc.</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-three d-md-flex">
                                    <div className="img-meta">
                                        <img src="images/media/img_07.jpg" alt=""/>
                                    </div>
                                    <div className="text-wrapper">
                                        <div className="icon d-flex justify-content-center align-items-center"><img
                                            src="images/icon/icon_15.svg" alt=""/></div>
                                        <p>It’s a great exprience to work with <a href="#">Sinco</a>. They’r vey humble
                                            and expert & the service has been excellent. The author is very quick and
                                            nice when it comes to support.</p>
                                        <div className="name">
                                            <h6>Rebecca Jones</h6>
                                            <span>Senior UX Designer, Creativegigs.</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <img src="images/shape/shape_12.svg" alt="" className="shapes shape-one"/>
            </div>

            <div className="fancy-feature-seven mt-140 lg-mt-50 sm-mt-20">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-lg-5">
                            <div className="block-style-five md-pb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title-three">Questions & Answers</div>
                                    <h2 className="main-title">Any Questions? Find here.</h2>
                                </div>

                                <p className="pt-10 pb-15">Don’t find your answer here? just send us a message for any
                                    query.</p>
                                <a  href="#" className="btn-five ripple-btn">Contact us</a>
                            </div>

                        </div>

                        <div className="col-lg-7 col-lg-6 ms-auto" data-aos="fade-left">
                            <div className="accordion accordion-style-one" id="accordionOne">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                            What is web hosting?
                                        </button>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse"
                                         aria-labelledby="headingOne" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>They not only understand what I say but read between the lines and also
                                                give me ideas of my own. AI technology is perfect for best business
                                                solutions. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                            How do you weigh different criteria in your process?
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>They not only understand what I say but read between the lines and also
                                                give me ideas of my own. AI technology is perfect for best business
                                                solutions. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                            What can I use to build my website?
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                         aria-labelledby="headingThree" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>They not only understand what I say but read between the lines and also
                                                give me ideas of my own. AI technology is perfect for best business
                                                solutions. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="true"
                                                aria-controls="collapseFour">
                                            If I already have a website, can I transfer it to your web hosting?
                                        </button>
                                    </div>
                                    <div id="collapseFour" className="accordion-collapse collapse show"
                                         aria-labelledby="headingFour" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>They not only understand what I say but read between the lines and also
                                                give me ideas of my own. AI technology is perfect for best business
                                                solutions. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                aria-expanded="false" aria-controls="collapseFive">
                                            How can I accept credit cards online?
                                        </button>
                                    </div>
                                    <div id="collapseFive" className="accordion-collapse collapse"
                                         aria-labelledby="headingFive" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>They not only understand what I say but read between the lines and also
                                                give me ideas of my own. AI technology is perfect for best business
                                                solutions. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img src="images/shape/shape_13.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_14.svg" alt="" className="shapes shape-two"/>
                <img src="images/shape/shape_15.svg" alt="" className="shapes shape-three"/>
            </div>

            <div className="blog-section-two mt-110 lg-mt-50">
                <div className="container">
                    <div className="title-style-one text-center mb-50 lg-mb-20" data-aos="fade-up">
                        <div className="sc-title-three">BLOG</div>
                        <h2 className="main-title">Company News</h2>
                    </div>


                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_04.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>A Discount Cartridge dsu is
                                        Better Ever.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i
                                        className="fas fa-chevron-right"></i></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_05.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>Truck Side Advertising Isn It
                                        Time for action.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i
                                        className="fas fa-chevron-right"></i></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_06.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>A discount cartridge dsu is
                                        better ever.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i
                                        className="fas fa-chevron-right"></i></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-lg-none d-flex" data-aos="fade-up">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_05.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>Truck Side Advertising Isn It
                                        Time for action.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i
                                        className="fas fa-chevron-right"></i></a>
                                </div>

                            </article>
                        </div>
                    </div>
                </div>
            </div>


            <div className="fancy-short-banner-one position-relative mt-160 lg-mt-100 md-mt-80">
                <div className="container">
                    <div className="bg-wrapper">
                        <div className="row align-items-center gx-xxl-5">
                            <div className="col-lg-6 text-center text-lg-start" data-aos="fade-right">
                                <div className="sub-title">Work Inquiry</div>
                                <h3 className="pe-xl-5 md-pb-20">Having Any Query! Book an appointment.</h3>
                            </div>
                            <div className="col-lg-6 text-center text-lg-end" data-aos="fade-left">
                                <a  href="#" className="msg-btn tran3s">Send Us Message</a>
                            </div>
                        </div>
                    </div>

                </div>

                <img src="images/shape/shape_10.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_11.svg" alt="" className="shapes shape-two"/>
            </div>


            <div className="footer-style-two theme-basic-footer">
                <img src="images/shape/shape_16.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_17.svg" alt="" className="shapes shape-two"/>
                <div className="container">
                    <div className="inner-wrapper">
                        <div className="row">
                            <div className="col-lg-3 footer-intro mb-40">
                                <div className="logo"><a  href="#"><img src="images/logo/logo_01.png" alt=""
                                                                                width="130"/></a></div>
                                <p>We will assist you in becoming more successful.</p>
                                <ul className="d-flex social-icon style-none">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Links</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a  href="#">Home</a></li>
                                    <li><a  href="#">Pricing</a></li>
                                    <li><a  href="#">About us</a></li>
                                    <li><a  href="#">Service</a></li>
                                    <li><a  href="#">Blog</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Legal</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a  href="#">Terms of use</a></li>
                                    <li><a  href="#">Terms & conditions</a></li>
                                    <li><a  href="#">Privacy policy</a></li>
                                    <li><a  href="#">Cookie policy</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-5 col-lg-4 col-md-6 mb-30">
                                <div className="newsletter ps-xl-5">
                                    <h5 className="footer-title">Subscribe </h5>
                                    <p>Join over <span>68,000</span> people getting our emails</p>
                                    <form action="#">
                                        <input type="email" placeholder="Enter your email"/>
                                        <button>Sign Up</button>
                                    </form>
                                    <div className="info">We only send interesting and relevant emails.</div>
                                </div>

                            </div>
                        </div>

                        <div className="bottom-footer">
                            <div className="d-lg-flex justify-content-between align-items-center">
                                <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                    <li><a  href="#">Privacy &amp; Terms.</a></li>
                                    <li><a  href="#">FAQ</a></li>
                                    <li><a  href="#">Contact Us</a></li>
                                </ul>
                                <p className="copyright text-center order-lg-0 pb-15">Copyright @2022 sinco inc.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <button className="scroll-top">
                <i className="bi bi-arrow-up-short"></i>
            </button>
        </div>
    );
};

export default Ai;