import React from 'react';


const Privacy = () => {
    return (
        <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-7 ms-auto">
                        <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                            <div className="title-style-three">
                                <div className="sc-title">Privacy & Terms</div>
                            </div>
                            <h2 className="main-title">Privacy Policy</h2>
                            <p className="pt-20 pb-15">At SNSTEC, we are committed to protecting your personal
                                information and ensuring transparency in how we handle your data. Our Privacy Policy
                                outlines the types of personal information we collect, how we use it, and your rights
                                regarding your data. We adhere to global data protection regulations, including GDPR and
                                CCPA, to ensure your privacy is safeguarded.</p>
                            <h2 className="main-title">Terms and Conditions</h2>
                            <p className="pt-20 pb-15">Our Terms and Conditions set the rules for using our website and
                                services. By accessing our site, you agree to comply with these terms, which include
                                guidelines on user conduct, intellectual property rights, and limitations of liability.
                                These terms help us maintain a safe and respectful environment for all users.</p>
                            <p className="pt-20 pb-15">For any questions or concerns about our Privacy Policy or Terms and Conditions, please contact us at privacy@snstec.com or +852 3596 7496. We are here to assist you and ensure your experience with SNSTEC is secure and satisfactory..</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;