import React from 'react';

const   ClientSupport =() =>{
    return (
        <div>

             
            <div className="hero-banner-three">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-6">
                            <h1 className="hero-heading">Next Level Client Support with lily.</h1>
                            <p className="text-lg mb-50 lg-mb-40">Sinco delivered blazing fast, striking ai solution</p>
                            <ul className="style-none button-group d-lg-flex align-items-center">
                                <li className="me-4"><a  href="#" className="btn-one ripple-btn">Start Free Trial</a></li>
                                <li className="help-btn">Need any help? <a  href="#">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div> 
                <div className="illustration-holder sm-mt-50">
                    <img src="images/assets/ils_07.svg" alt="" className="main-illustration transform-img-meta ms-auto"/>
                        <img src="images/shape/shape_19.png" alt="" className="shapes shape-one"/>
                </div> 
                <img src="images/shape/shape_18.svg" alt="" className="shapes cube-shape"/>
            </div> 


 
            <div className="fancy-feature-eight position-relative">
                <div className="container">
                    <div className="row justify-content-center gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex mb-30" data-aos="fade-up">
                            <div className="block-style-seven">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_17.svg" alt=""/></div>
                                <h5><a  href="#">Data Science</a></h5>
                                <p>Convert data noise to intelligent insights for quis competitive differentiation.</p>
                                <a  href="#" className="tran3s more-btn"><img src="images/icon/icon_20.svg" alt=""/></a>
                            </div> 
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex mb-30" data-aos="fade-up" data-aos-delay="100">
                            <div className="block-style-seven">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_18.svg" alt=""/></div>
                                <h5><a  href="#">Machine Learning</a></h5>
                                <p>Convert data noise to intelligent insights for quis competitive differentiation.</p>
                                <a  href="#" className="tran3s more-btn"><img src="images/icon/icon_20.svg" alt=""/></a>
                            </div> 
                        </div>
                        <div className="col-lg-4 d-flex mb-30" data-aos="fade-up" data-aos-delay="200">
                            <div className="block-style-seven">
                                <div className="icon d-flex align-items-end"><img src="images/icon/icon_19.svg" alt=""/></div>
                                <h5><a  href="#">Customer Support</a></h5>
                                <p>Convert data noise to intelligent insights for quis competitive differentiation.</p>
                                <a  href="#" className="tran3s more-btn"><img src="images/icon/icon_20.svg" alt=""/></a>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div> 


 
            <div className="fancy-feature-nine mt-190 lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 col-lg-6 ms-auto order-lg-last">
                            <div className="block-style-two md-mb-50" data-aos="fade-left">
                                <div className="title-style-one">
                                    <div className="sc-title-four">What is Chatbot</div>
                                    <h2 className="main-title">Customer service by our chatbot.</h2>
                                </div> 
                                <ul className="nav nav-tabs justify-content-between" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#sp1" type="button" role="tab">Speech</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#sp2" type="button" role="tab">Auto Text</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#sp3" type="button" role="tab">Q&A</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#sp4" type="button" role="tab">Generate Leads</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="sp1">
                                        <p className="pt-10 pb-10">Save web pages (without the ads) and mark them up with arrows, highlights, and text.</p>
                                        <ul className="style-none list-item">
                                            <li>Various analysis options.</li>
                                            <li>Page Load (time, size, number of requests).</li>
                                            <li>Big data analysis.</li>
                                        </ul>
                                    </div> 
                                    <div className="tab-pane fade" id="sp2">
                                        <p className="pt-10 pb-10">Sinco is data science, machine learning and artificial intelligence provide business solution.</p>
                                        <ul className="style-none list-item">
                                            <li>Page Load (time, size, number of requests).</li>
                                            <li>Various analysis options.</li>
                                            <li>Big data analysis.</li>
                                        </ul>
                                    </div> 
                                    <div className="tab-pane fade" id="sp3">
                                        <p className="pt-10 pb-10">Save web pages (without the ads) and mark them up with arrows, highlights, and text.</p>
                                        <ul className="style-none list-item">
                                            <li>Various analysis options.</li>
                                            <li>Page Load (time, size, number of requests).</li>
                                            <li>Big data analysis.</li>
                                        </ul>
                                    </div> 
                                    <div className="tab-pane fade" id="sp4">
                                        <p className="pt-10 pb-10">Sinco is data science, machine learning and artificial intelligence provide business solution.</p>
                                        <ul className="style-none list-item">
                                            <li>Page Load (time, size, number of requests).</li>
                                            <li>Various analysis options.</li>
                                            <li>Big data analysis.</li>
                                        </ul>
                                    </div> 
                                </div> 

                            </div> 
                        </div>

                        <div className="col-lg-6 order-lg-first text-center text-lg-start" data-aos="fade-right">
                            <div className="illustration-holder d-inline-block ms-xxl-5 mt-40 lg-mt-10">
                                <img src="images/assets/ils_08.svg" alt="" className="transform-img-meta"/>
                                    <img src="images/assets/ils_08_1.svg" alt="" className="shapes shape-one"/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 


 
            <div className="fancy-feature-ten mt-190 lg-mt-110">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="block-style-two md-mb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title-four">App Integration</div>
                                    <h2 className="main-title">Chatbot platform works all with your tools.</h2>
                                </div> 
                                <p className="pt-25 pb-30 lg-pt-20 lg-pb-10">Sinco is data science, machine learning and artificial intelligence provide business solution and delivered blazing fast, striking result.</p>
                                <div className="btn-three color-three">How to integrate? <a  href="#">Click here <i className="fas fa-chevron-right"></i></a></div>
                            </div> 
                        </div>

                        <div className="col-xl-6 col-lg-7 col-md-6 ms-auto" data-aos="fade-left">
                            <div className="screen-holder-one d-flex align-items-center justify-content-center">
                                <div className="round-bg d-flex align-items-center justify-content-center"                   style={{width:"200px",height: "200px"}}><img src="images/logo/Plogo-16.png" alt=""/></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-one"   style={{width:"70px",height: "70px"}}><img src="images/logo/Plogo-17.png" alt=""/></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-two"   style={{width:"115px",height: "115px"}}><img src="images/logo/Plogo-18.png" alt=""/></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-three" style={{width:"89px",height: "89px"}}><img src="images/logo/Plogo-19.png" alt=""/></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-four"  style={{width:"162px",height: "162px"}}><img src="images/logo/Plogo-20.png" alt=""/></div>
                                <div className="round-bg d-flex align-items-center justify-content-center shapes logo-five"  style={{width:"115px",height: "115px"}}><img src="images/logo/Plogo-21.png" alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 

 
            <div className="fancy-feature-eleven mt-225 lg-mt-120">
                <div className="container">
                    <div className="title-style-one white-vr text-center mb-55 lg-mb-30" data-aos="fade-up">
                        <h2 className="main-title">How It works</h2>
                        <p className="sub-title">Our advance AI system work seamlesly & smartly.</p>
                    </div> 

                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row justify-content-center gx-xxl-5">
                                <div className="col-md-4 col-sm-6" data-aos="fade-right">
                                    <div className="block-style-eight position-relative mt-50">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/icon_21.svg" alt=""/>
                                                <div className="num">1</div>
                                        </div>
                                        <h5>Identify the probelm <br/> with ai</h5>
                                    </div> 
                                </div>
                                <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                    <div className="block-style-eight position-relative mt-50 shape-arrow">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/icon_22.svg" alt=""/>
                                                <div className="num">2</div>
                                        </div>
                                        <h5>Collect data with our <br/> advance ai.</h5>
                                    </div> 
                                </div>
                                <div className="col-md-4 col-sm-6" data-aos="fade-left" data-aos-delay="200">
                                    <div className="block-style-eight position-relative mt-50">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <img src="images/icon/icon_23.svg" alt=""/>
                                                <div className="num">3</div>
                                        </div>
                                        <h5>Deliver Accurate data <br/> solution.</h5>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <img src="images/shape/shape_20.svg" alt="" className="shapes shape-one" />
                    <img src="images/shape/shape_21.svg" alt="" className="shapes shape-two" />
                        <img src="images/shape/shape_22.png" alt="" className="shapes shape-three" />
                            <img src="images/shape/shape_23.png" alt="" className="shapes shape-four" />
            </div> 


 
            <div className="counter-section-one top-transform">
                <div className="container">
                    <div className="inner-container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one color-two text-center mb-20 mt-10 lg-mt-20">
                                    <div className="main-count"><span className="counter">20</span>mil</div>
                                    <p>Human labor hours saved <br/> with help of AI</p>
                                </div> 
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one color-two text-center mb-20 mt-10 lg-mt-20">
                                    <div className="main-count"><span className="counter">1.3</span>b+</div>
                                    <p>Generated revenue by <br/>AI Solutions</p>
                                </div> 
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one color-two text-center mb-20 mt-10 lg-mt-20">
                                    <div className="main-count">$<span className="counter">15</span>mil+</div>
                                    <p>Saved operational costs <br/>due to AI</p>
                                </div> 
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 

 
            <div className="fancy-feature-twelve mt-170 lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-6 order-md-last">
                            <div className="block-style-nine pt-30 sm-pt-10">
                                <div className="title-style-one pb-10" data-aos="fade-up">
                                    <div className="sc-title-four">WHY CHOOSE US</div>
                                    <h2 className="main-title">Why choose us for your business.</h2>
                                </div> 
                                <ul className="style-none list-item">
                                    <li data-aos="fade-up">Learn content by interacting with an expert lesson or watching a video.</li>
                                    <li data-aos="fade-up" data-aos-delay="100">Practice what you learned on realistic SAT test questions.</li>
                                    <li data-aos="fade-up" data-aos-delay="200">Review your practice questions and learn how to improve.</li>
                                </ul>
                            </div> 
                        </div>
                        <div className="col-xl-7 col-md-6 order-md-first" data-aos="fade-right">
                            <div className="illustration-holder position-relative d-inline-block ms-5 sm-mt-30">
                                <img src="images/assets/ils_09.svg" alt="" className="transform-img-meta"/>
                                    <div className="card-one shapes d-flex align-items-center justify-content-center">
                                        <div className="icon"><i className="bi bi-check-lg"></i></div>
                                        <h6>A++ Performance</h6>
                                    </div> 
                                    <div className="card-two shapes text-center">
                                        <div className="icon"><i className="bi bi-check-lg"></i></div>
                                        <div className="main-count"><span className="counter">20</span>k</div>
                                        <div className="info">5 Start Rating</div>
                                        <ul className="style-none d-flex justify-content-center rating">
                                            <li><i className="bi bi-star-fill"></i></li>
                                            <li><i className="bi bi-star-fill"></i></li>
                                            <li><i className="bi bi-star-fill"></i></li>
                                            <li><i className="bi bi-star-fill"></i></li>
                                            <li><i className="bi bi-star-fill"></i></li>
                                        </ul>
                                    </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <img src="images/shape/shape_25.svg" alt="" className="shapes bg-shape"/>
            </div> 

 
            <div className="feedback-section-four position-relative mt-130 pb-200 lg-mt-100 lg-pb-80">
                <div className="container">
                    <div className="title-style-one text-center" data-aos="fade-up">
                        <div className="sc-title-four">FEEDBACK</div>
                        <h2 className="main-title">Trsuted by Clients</h2>
                    </div> 
                </div> 
                <div className="inner-content mt-90 lg-mt-60">
                    <div className="slider-wrapper">
                        <div className="feedback_slider_one">
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-1.png" alt=""/>
                                                <ul className="style-none d-flex rating pt-15">
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div> 
                                    <p>Certainly from my perspective quis been a great success with due WP giving us that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 5.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div> 
                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-2.png" alt=""/>
                                                <ul className="style-none d-flex rating pt-15">
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div> 
                                    <p>Certainly from my perspective quis been a great success with due WP giving us that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 35.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div> 
                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-3.png" alt=""/>
                                                <ul className="style-none d-flex rating pt-15">
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div> 
                                    <p>Certainly from my perspective quis been a great success with due WP giving us that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 19.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div> 
                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-4.png" alt=""/>
                                                <ul className="style-none d-flex rating pt-15">
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                    <li><i className="bi bi-star-fill"></i></li>
                                                </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div> 
                                    <p>Certainly from my perspective quis been a great success with due WP giving us that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 15.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div> 
                </div> 

                <div className="container">
                    <div className="row">
                        <div className="col-12 m-auto">
                            <ul className="style-none text-center mt-40 lg-mt-20">
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-13.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="100"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-12.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="200"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-9.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="300"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-7.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up" data-aos-delay="400"><a href="#" className="d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-8.png" alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src="images/shape/shape_26.svg" alt="" className="shapes bg-shape"/>
            </div> 


 
            <div className="fancy-short-banner-two">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row align-items-top">
                            <div className="col-xl-5 col-lg-6 order-lg-last" data-aos="fade-left">
                                <div className="title-style-two">
                                    <div className="sc-title">DOWNLOAD APP</div>
                                    <h2 className="main-title">Get our mobile Application.</h2>
                                    <p className="sub-title">Get our mobile app for best & fast chatboot services</p>
                                </div> 
                                <div className="d-sm-flex align-items-center button-group mt-40 lg-mt-30">
                                    <a href="#" className="d-flex align-items-center windows-button">
                                        <img src="images/icon/playstore.svg" alt="" className="icon"/>
                                            <div>
                                                <span>Get it on</span>
                                                <strong>Google play</strong>
                                            </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center ios-button">
                                        <img src="images/icon/apple.svg" alt="" className="icon"/>
                                            <div>
                                                <span>Download on the</span>
                                                <strong>App store</strong>
                                            </div>
                                    </a>
                                </div> 
                            </div>
                            <div className="col-xxl-6 col-xl-7 col-md-6 col-sm-9 order-lg-first m-auto me-lg-0 ms-lg-0">
                                <div className="mobile-screen md-mt-40" data-aos="fade-up"><img src="images/media/screen_01.png" alt=""/></div>
                            </div>
                        </div>
                    </div> 
                    <img src="images/shape/shape_27.png" alt="" className="shapes shape-one"/>
                        <img src="images/shape/shape_28.png" alt="" className="shapes shape-two"/>
                            <img src="images/shape/shape_29.png" alt="" className="shapes shape-three"/>
                </div> 
            </div> 




            <div className="blog-section-two mt-170 lg-mt-110">
                <div className="container">
                    <div className="title-style-one text-center mb-50 lg-mb-20" data-aos="fade-up">
                        <h2 className="main-title">Company News</h2>
                    </div> 

                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#" className="w-100 d-block"><img src="images/blog/blog_img_04.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>A Discount Cartridge dsu is Better Ever.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i className="fas fa-chevron-right"></i></a>
                                </div> 
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#" className="w-100 d-block"><img src="images/blog/blog_img_05.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>Truck Side Advertising Isn It Time for action.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i className="fas fa-chevron-right"></i></a>
                                </div> 
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#" className="w-100 d-block"><img src="images/blog/blog_img_06.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>A discount cartridge dsu is better ever.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i className="fas fa-chevron-right"></i></a>
                                </div> 
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-lg-none d-flex" data-aos="fade-up">
                            <article className="blog-meta-two mt-30">
                                <figure className="post-img m0"><a  href="#" className="w-100 d-block"><img src="images/blog/blog_img_05.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">23 Apr, 2021</a></div>
                                    <a  href="#" className="blog-title"><h5>Truck Side Advertising Isn It Time for action.</h5></a>
                                    <a  href="#" className="read-btn tran3s">Continue Reading <i className="fas fa-chevron-right"></i></a>
                                </div> 
                            </article>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="address-section-one">
                <div className="container">
                    <div className="inner-content bg-white" data-aos="fade-up">
                        <div className="row g-0">
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex border-right">
                                    <div className="icon"><img src="images/icon/icon_06.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Our Address</h4>
                                        <p>1012 Pebda Parkway, Mirpur 2 <br/>Dhaka, Bangladesh</p>
                                    </div> 
                                </div> 
                            </div>
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex">
                                    <div className="icon"><img src="images/icon/icon_07.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Contact Info</h4>
                                        <p>Open a chat or give us call at <br/><a href="#">310.841.5500</a></p>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div> 





            <div className="footer-style-three theme-basic-footer">
                <img src="images/shape/shape_30.png" alt="" className="shapes shape-one" />
                    <img src="images/shape/shape_28.png" alt="" className="shapes shape-two" />
                        <img src="images/shape/shape_29.png" alt="" className="shapes shape-three" />
                            <div className="container">
                                <div className="inner-wrapper">
                                    <div className="row">
                                        <div className="col-lg-3 footer-intro mb-40">
                                            <div className="logo"><a  href="#"><img src="images/logo/logo_02.png" alt="" width="129"/></a></div>
                                            <p>We will assist you in becoming more successful.</p>
                                            <ul className="d-flex social-icon style-none">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-2 col-md-3 col-sm-6 mb-30">
                                            <h5 className="footer-title">Links</h5>
                                            <ul className="footer-nav-link style-none">
                                                <li><a  href="#">Home</a></li>
                                                <li><a  href="#">Pricing</a></li>
                                                <li><a  href="#">About us</a></li>
                                                <li><a  href="#">Service</a></li>
                                                <li><a  href="#">Blog</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                            <h5 className="footer-title">Legal</h5>
                                            <ul className="footer-nav-link style-none">
                                                <li><a  href="#">Terms of use</a></li>
                                                <li><a  href="#">Terms & conditions</a></li>
                                                <li><a  href="#">Privacy policy</a></li>
                                                <li><a  href="#">Cookie policy</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-5 col-lg-4 col-md-6 mb-30">
                                            <div className="newsletter ps-xl-5">
                                                <h5 className="footer-title">Subscribe </h5>
                                                <p>Join over <span>68,000</span> people getting our emails</p>
                                                <form action="#">
                                                    <input type="email" placeholder="Enter your email"/>
                                                        <button>Sign Up</button>
                                                </form>
                                                <div className="info">We only send interesting and relevant emails.</div>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="bottom-footer">
                                        <div className="d-lg-flex justify-content-between align-items-center">
                                            <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                                <li><a  href="#">Privacy &amp; Terms.</a></li>
                                                <li><a  href="#">FAQ</a></li>
                                                <li><a  href="#">Contact Us</a></li>
                                            </ul>
                                            <p className="copyright text-center order-lg-0 pb-15">Copyright @2022 sinco inc.</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
            </div> 


            <button className="scroll-top">
                <i className="bi bi-arrow-up-short"></i>
            </button>
        </div>
    );
}

export default ClientSupport;