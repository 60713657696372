import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-7 ms-auto">
                        <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                            <div className="title-style-three">
                                <div className="sc-title">Privacy Policy</div>
                            </div>
                            <p className="pt-20 pb-15">At SNSTEC, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
                            <h2 className="main-title">1. Information We Collect</h2>
                            <p className="pt-20 pb-15">We may collect personal information that you provide directly to us, such as when you register for an account, subscribe to our newsletter, or contact us for support. This information may include your name, email address, phone number, and any other details you choose to provide.</p>
                            <h2 className="main-title">2. How We Use Your Information</h2>
                            <p className="pt-20 pb-15">
                                We use the information we collect to:
                                <ul>
                                    <li>Provide, operate, and maintain our services</li>
                                    <li>Improve, personalize, and expand our services</li>
                                    <li>Understand and analyze how you use our services</li>
                                    <li>Communicate with you, either directly</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;