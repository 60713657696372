import React from 'react';

const MachineLearning = () => {
    return (
        <div>

            <div className="hero-banner-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-7">
                            <a  href="#" className="slogan"><strong>Offer</strong> is going on till friday,
                                $1.99/mo. <i className="fas fa-chevron-right"></i></a>
                            <h1 className="hero-heading">Ai & Data Machine deep Learning.</h1>
                            <p className="text-lg mb-60 lg-mb-40">Sinco delivered blazing fast, striking ai solution</p>
                            <ul className="style-none button-group d-lg-flex align-items-center">
                                <li className="me-4"><a  href="#" className="btn-one ripple-btn">Start Free
                                    Trial</a></li>
                                <li className="help-btn">Need any help? <a  href="#">Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="illustration-holder">
                    <img src="images/assets/ils_01.svg" alt="" className="main-illustration ms-auto"/>
                    <img src="images/assets/bg_01.png" alt="" className="shapes bg-shape"/>
                    <img src="images/assets/ils_01_1.svg" alt="" className="shapes shape-one"/>
                    <img src="images/assets/ils_01_2.svg" alt="" className="shapes shape-two"/>
                    <img src="images/assets/ils_01_3.svg" alt="" className="shapes shape-three"/>
                    <img src="images/assets/ils_01_4.svg" alt="" className="shapes shape-four"/>
                    <img src="images/assets/ils_01_5.svg" alt="" className="shapes shape-five"/>
                    <img src="images/assets/ils_01_6.svg" alt="" className="shapes shape-six"/>
                    <img src="images/assets/ils_01_7.svg" alt="" className="shapes shape-seven"/>
                    <div className="card-one shapes">
                        <div className="icon"><i className="bi bi-check-lg"></i></div>
                        <h6>Data Automation <br/>Solution.</h6>
                        <span className="info">faster process</span>
                    </div>

                    <div className="card-two shapes">
                        <div className="icon"><i className="bi bi-check-lg"></i></div>
                        <h6>Data Quality Audit</h6>
                    </div>

                </div>

            </div>



            <div className="fancy-feature-one position-relative mt-225 xl-mt-200 lg-mt-150">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div className="title-style-one">
                                <div className="sc-title">Services</div>
                                <h2 className="main-title">Our Experties field.</h2>
                            </div>

                            <p className="sub-heading mt-25 mb-50 md-mb-20">Sinco is data science, machine learning &
                                artificial intelligence.</p>
                            <div className="btn-three">For more details. <a  href="#">Click here <i
                                className="fas fa-chevron-right"></i></a></div>
                        </div>
                    </div>
                </div>

                <div className="slider-wrapper">
                    <div className="service_slider_one">
                        <div className="item">
                            <div className="block-style-one text-center">
                                <div className="icon d-flex align-items-end justify-content-center mb-50 lg-mb-30"><img
                                    src="images/icon/icon_02.svg" alt="" className="m-auto"/></div>
                                <h5 className="mb-40">Data Machine Learning.</h5>
                                <a  href="#" className="btn-two">Learn more <i
                                    className="fas fa-chevron-right"></i></a>
                            </div>

                        </div>

                        <div className="item">
                            <div className="block-style-one text-center">
                                <div className="icon d-flex align-items-end justify-content-center mb-50 lg-mb-30"><img
                                    src="images/icon/icon_03.svg" alt="" className="m-auto"/></div>
                                <h5 className="mb-40">AI, Machine Learning</h5>
                                <a  href="#" className="btn-two">Learn more <i
                                    className="fas fa-chevron-right"></i></a>
                            </div>

                        </div>

                        <div className="item">
                            <div className="block-style-one text-center">
                                <div className="icon d-flex align-items-end justify-content-center mb-50 lg-mb-30"><img
                                    src="images/icon/icon_04.svg" alt="" className="m-auto"/></div>
                                <h5 className="mb-40">Data Development</h5>
                                <a  href="#" className="btn-two">Learn more <i
                                    className="fas fa-chevron-right"></i></a>
                            </div>

                        </div>

                        <div className="item">
                            <div className="block-style-one text-center">
                                <div className="icon d-flex align-items-end justify-content-center mb-50 lg-mb-30"><img
                                    src="images/icon/icon_03.svg" alt="" className="m-auto"/></div>
                                <h5 className="mb-40">Big Data Consulting</h5>
                                <a  href="#" className="btn-two">Learn more <i
                                    className="fas fa-chevron-right"></i></a>
                            </div>

                        </div>

                    </div>
                </div>

            </div>




            <div className="fancy-feature-two position-relative mt-200 lg-mt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-one">
                                    <div className="sc-title">Over 150k+ Client</div>
                                    <h2 className="main-title">We offer Real-time Data Solutions.</h2>
                                </div>

                                <p className="pt-10 pb-20 lg-pb-10">Sinco is data science, machine learning and
                                    artificial intelligence provide business solution.</p>
                                <ul className="style-none list-item">
                                    <li>Various analysis options.</li>
                                    <li>Page Load (time, size, number of requests).</li>
                                    <li>Big data analysis.</li>
                                </ul>
                                <div className="btn-three mt-45 lg-mt-30">Want to learn more about us? <a
                                     href="#">Click here <i className="fas fa-chevron-right"></i></a></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="illustration-holder sm-mt-20">
                    <img src="images/assets/ils_02.svg" alt="" className="main-illustration w-100"/>
                    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-one"/>
                    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-two"/>
                    <img src="images/assets/ils_02_1.svg" alt="" className="shapes shape-three"/>
                    <img src="images/assets/ils_02_2.svg" alt="" className="shapes shape-four" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="1500"/>
                    <img src="images/assets/ils_02_2.svg" alt="" className="shapes shape-five" data-aos="fade-down"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="200" data-aos-duration="1500"/>
                    <img src="images/assets/ils_02_3.svg" alt="" className="shapes shape-six" data-aos="fade-down"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="1500"/>
                    <img src="images/assets/ils_02_4.svg" alt="" className="shapes shape-seven" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="250" data-aos-duration="1500"/>
                </div>

            </div>

            <div className="counter-section-one mt-150 md-mt-80">
                <div className="container">
                    <div className="inner-container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">20</span>mil</div>
                                    <p>Human labor hours saved <br/> with help of AI</p>
                                </div>

                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">1.3</span>b+</div>
                                    <p>Generated revenue by <br/>AI Solutions</p>
                                </div>

                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count">$<span className="counter">15</span>mil+</div>
                                    <p>Saved operational costs <br/>due to AI</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="fancy-feature-three position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="block-style-two pe-xxl-5" data-aos="fade-right">
                                <div className="title-style-one">
                                    <div className="sc-title">Working Process</div>
                                    <h2 className="main-title">Let’s see how do we works.</h2>
                                </div>

                                <p className="pt-20 pb-30 lg-pb-10">Sinco is data science, machine learning and
                                    artificial intelligence provide business solution and delivered blazing fast,
                                    striking result.</p>
                                <div className="btn-three">Want to learn more about us? <a  href="#">Click
                                    here <i className="fas fa-chevron-right"></i></a></div>
                            </div>

                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-6 ms-auto text-end">
                            <div className="illustration-holder position-relative d-inline-block sm-mt-50">
                                <img src="images/assets/ils_03.svg" alt="" className="main-illustration w-100"/>
                                <img src="images/assets/ils_03_1.svg" alt="" className="shapes shape-one"/>
                                <img src="images/assets/ils_03_2.svg" alt="" className="shapes shape-two"/>
                                <img src="images/assets/ils_03_2.svg" alt="" className="shapes shape-three"/>
                                <img src="images/assets/ils_03_4.svg" alt="" className="shapes shape-four"/>
                                <img src="images/assets/ils_03_3.svg" alt="" className="shapes shape-five"
                                     data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500"/>
                                <img src="images/assets/ils_03_3.svg" alt="" className="shapes shape-six"
                                     data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500"/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-100 lg-mt-70">
                    <div className="container">
                        <div className="row justify-content-center gx-xxl-5">
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                                <div className="block-style-three mb-25">
                                    <div className="numb">1</div>
                                    <h6>Data Automation Solution.</h6>
                                    <p>Learn content by interacting quis expert lesson a video.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="150">
                                <div className="block-style-three mb-25">
                                    <div className="numb">2</div>
                                    <h6>Analytics Business.</h6>
                                    <p>Practice what you realistic SAT test questions.</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                                <div className="block-style-three mb-25">
                                    <div className="numb">3</div>
                                    <h6>A New Breed Of AI.</h6>
                                    <p>Review your practice and learn how to improve.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-gallery-one mt-150 lg-mt-110" data-aos="fade-up">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-7 col-sm-8">
                            <div className="title-style-one text-center text-sm-start xs-pb-20">
                                <h2 className="main-title">Check Some of Our Recent Work.</h2>
                            </div>

                        </div>
                        <div
                            className="col-xl-6 col-lg-7 col-md-5 col-sm-4 ms-auto d-flex justify-content-center justify-content-sm-end">
                            <ul className="slider-arrows slick-arrow-one d-flex style-none">
                                <li className="prev_p1 slick-arrow ripple-btn"  ><i
                                    className="bi bi-arrow-left"></i></li>
                                <li className="next_p1 slick-arrow ripple-btn" ><i
                                    className="bi bi-arrow-right"></i></li>
                            </ul>
                        </div>
                    </div>

                    <div className="slider-wrapper">
                        <div className="portfolio_slider_one">
                            <div className="item">
                                <div className="portfolio-block-one">
                                    <div className="img-meta"><img src="images/gallery/img_01.jpg" alt=""
                                                                   className="w-100"/></div>
                                    <a  href="#"
                                       className="title tran3s d-flex flex-column justify-content-center align-items-center">
                                        <span className="tag">Data Research</span>
                                        <span className="pj-name">Product Analysis</span>
                                    </a>
                                    <div className="hover-state tran3s"><a className="fancybox tran3s" data-fancybox=""
                                                                           title="Click for large view"
                                                                           href="images/gallery/img_01.jpg"
                                                                          ><i
                                        className="bi bi-plus"></i></a></div>
                                </div>

                            </div>


                            <div className="item">
                                <div className="portfolio-block-one">
                                    <div className="img-meta"><img src="images/gallery/img_02.jpg" alt=""
                                                                   className="w-100"/></div>
                                    <a  href="#"
                                       className="title tran3s d-flex flex-column justify-content-center align-items-center">
                                        <span className="tag">Design</span>
                                        <span className="pj-name">UI, UX Design</span>
                                    </a>
                                    <div className="hover-state tran3s"><a className="fancybox tran3s" data-fancybox=""
                                                                           title="Click for large view"
                                                                           href="images/gallery/img_02.jpg"
                                                                           ><i
                                        className="bi bi-plus"></i></a></div>
                                </div>

                            </div>


                            <div className="item">
                                <div className="portfolio-block-one">
                                    <div className="img-meta"><img src="images/gallery/img_03.jpg" alt=""
                                                                   className="w-100"/></div>
                                    <a  href="#"
                                       className="title tran3s d-flex flex-column justify-content-center align-items-center">
                                        <span className="tag">Marketing</span>
                                        <span className="pj-name">Content Marketing</span>
                                    </a>
                                    <div className="hover-state tran3s"><a className="fancybox tran3s" data-fancybox=""
                                                                           title="Click for large view"
                                                                           href="images/gallery/img_03.jpg"
                                                                         ><i
                                        className="bi bi-plus"></i></a></div>
                                </div>

                            </div>


                            <div className="item">
                                <div className="portfolio-block-one">
                                    <div className="img-meta"><img src="images/gallery/img_04.jpg" alt=""
                                                                   className="w-100"/></div>
                                    <a  href="#"
                                       className="title tran3s d-flex flex-column justify-content-center align-items-center">
                                        <span className="tag">Development</span>
                                        <span className="pj-name">Market System</span>
                                    </a>
                                    <div className="hover-state tran3s"><a className="fancybox tran3s" data-fancybox=""
                                                                           title="Click for large view"
                                                                           href="images/gallery/img_04.jpg"
                                                                           ><i
                                        className="bi bi-plus"></i></a></div>
                                </div>

                            </div>


                            <div className="item">
                                <div className="portfolio-block-one">
                                    <div className="img-meta"><img src="images/gallery/img_05.jpg" alt=""
                                                                   className="w-100"/></div>
                                    <a  href="#"
                                       className="title tran3s d-flex flex-column justify-content-center align-items-center">
                                        <span className="tag">Marketing</span>
                                        <span className="pj-name">Content Marketing</span>
                                    </a>
                                    <div className="hover-state tran3s"><a className="fancybox tran3s" data-fancybox=""
                                                                           title="Click for large view"
                                                                           href="images/gallery/img_05.jpg"
                                                                           ><i
                                        className="bi bi-plus"></i></a></div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>


            <div className="pricing-section-one mt-150 lg-mt-110">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-8 col-lg-7 col-md-9 m-auto">
                            <div className="title-style-one text-center">
                                <h2 className="main-title">Solo, Agency or Team? We’ve got you Covered</h2>
                            </div>

                        </div>
                    </div>

                    <ul className="nav nav-tabs justify-content-center pricing-nav-one" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#month"
                                    type="button" role="tab">Monthly
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#year" type="button"
                                    role="tab">Yearly
                            </button>
                        </li>
                    </ul>
                </div>


                <div className="pricing-table-area-one" data-aos="fade-up" data-aos-delay="100">
                    <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane active show" id="month">
                                <div className="row gx-xxl-5">
                                    <div className="col-md-6">
                                        <div className="pr-table-wrapper active md-mb-30">
                                            <div className="pack-name">Business</div>
                                            <div className="pack-details">For individuals and teams.
                                                Get <span>1 year <br/> premium market access</span></div>
                                            <div
                                                className="top-banner d-sm-flex justify-content-center align-items-center">
                                                <div className="price"><sup>$</sup>75</div>
                                                <div>
                                                    <span>Monthly membership</span>
                                                    <em>Starting at $75/mo with </em>
                                                </div>
                                            </div>

                                            <ul className="pr-feature style-none">
                                                <li>Unlimited campaigns</li>
                                                <li>Push Notifications</li>
                                                <li>Team fundraising</li>
                                            </ul>
                                            <a  href="#" className="trial-button">Try us without risk. <span>Choose plan <i
                                                className="fas fa-chevron-right"></i></span> </a>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="pr-table-wrapper">
                                            <div className="pack-name">Agency</div>
                                            <div className="pack-details">For big agency & teams. Get <span>1 year <br/> premium market access</span>
                                            </div>
                                            <div
                                                className="top-banner d-sm-flex justify-content-center align-items-center">
                                                <div className="price"><sup>$</sup>99</div>
                                                <div>
                                                    <span>Monthly membership</span>
                                                    <em>Starting at $99/mo with </em>
                                                </div>
                                            </div>

                                            <ul className="pr-feature style-none">
                                                <li>Unlimited campaigns</li>
                                                <li>Push Notifications</li>
                                                <li>Team fundraising</li>
                                            </ul>
                                            <a  href="#" className="trial-button">Try us without risk. <span>Choose plan <i
                                                className="fas fa-chevron-right"></i></span> </a>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="year">
                                <div className="row gx-xxl-5">
                                    <div className="col-md-6">
                                        <div className="pr-table-wrapper active md-mb-30">
                                            <div className="pack-name">Business</div>
                                            <div className="pack-details">For individuals and teams.
                                                Get <span>2 year <br/> premium market access</span></div>
                                            <div
                                                className="top-banner d-sm-flex justify-content-center align-items-center">
                                                <div className="price"><sup>$</sup>69</div>
                                                <div>
                                                    <span>Yearly membership</span>
                                                    <em>Starting at $69/mo with </em>
                                                </div>
                                            </div>

                                            <ul className="pr-feature style-none">
                                                <li>Unlimited campaigns</li>
                                                <li>Push Notifications</li>
                                                <li>Team fundraising</li>
                                            </ul>
                                            <a  href="#" className="trial-button">Try us without risk. <span>Choose plan <i
                                                className="fas fa-chevron-right"></i></span> </a>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="pr-table-wrapper">
                                            <div className="pack-name">Agency</div>
                                            <div className="pack-details">For big agency & teams. Get <span>2 year <br/> premium market access</span>
                                            </div>
                                            <div
                                                className="top-banner d-sm-flex justify-content-center align-items-center">
                                                <div className="price"><sup>$</sup>97</div>
                                                <div>
                                                    <span>Yearly membership</span>
                                                    <em>Starting at $97/mo with </em>
                                                </div>
                                            </div>

                                            <ul className="pr-feature style-none">
                                                <li>Unlimited campaigns</li>
                                                <li>Push Notifications</li>
                                                <li>Team fundraising</li>
                                            </ul>
                                            <a  href="#" className="trial-button">Try us without risk. <span>Choose plan <i
                                                className="fas fa-chevron-right"></i></span> </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="msg-note mt-80 lg-mt-50" data-aos="fade-up">If you Need any Custom or others
                            Pricing System. <br/> Please <a  href="#">Send Message</a></div>
                    </div>
                </div>

            </div>

            <div className="feedback-section-one mt-130 lg-mt-100">
                <div className="container">
                    <div className="title-style-one text-center" data-aos="fade-up">
                        <div className="sc-title">TESTIMONIALS</div>
                        <h2 className="main-title">Word from Our Client</h2>
                    </div>

                </div>

                <div className="inner-content mt-150 lg-mt-80">
                    <div className="slider-wrapper">
                        <div className="feedback_slider_one">
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-1.png" alt=""/>
                                            <ul className="style-none d-flex rating pt-15">
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                            </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div>

                                    <p>Certainly from my perspective quis been a great success with due WP giving us
                                        that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 5.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-2.png" alt=""/>
                                            <ul className="style-none d-flex rating pt-15">
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                            </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div>

                                    <p>Certainly from my perspective quis been a great success with due WP giving us
                                        that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 35.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-3.png" alt=""/>
                                            <ul className="style-none d-flex rating pt-15">
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                            </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div>

                                    <p>Certainly from my perspective quis been a great success with due WP giving us
                                        that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 19.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                            <div className="item">
                                <div className="feedback-block-one">
                                    <div className="top-header d-flex align-items-center justify-content-between">
                                        <div>
                                            <img src="images/logo/Plogo-4.png" alt=""/>
                                            <ul className="style-none d-flex rating pt-15">
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                            </ul>
                                        </div>
                                        <img src="images/icon/icon_05.svg" alt="" width="50"/>
                                    </div>

                                    <p>Certainly from my perspective quis been a great success with due WP giving us
                                        that enterprises level assured quality.</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="cost"><span>Qulaity & Cost:</span> 15.00</div>
                                        <img src="images/logo/Plogo-5.png" alt=""/>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div className="partner-section-one">
                <div className="container">
                    <div className="title-style-one text-center" data-aos="fade-up">
                        <div className="sc-title">over 150k+ client</div>
                        <h2 className="main-title md">Join 27,000+ companies who’ve reached </h2>
                    </div>


                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <ul className="style-none text-center mt-40 lg-mt-10">
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"><a href="#"
                                                                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-6.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="100"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-7.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="200"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-8.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="300"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-9.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="400"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-10.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="100"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-11.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="200"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-12.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="300"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-13.png" alt=""/></a></li>
                                <li className="partner-logo-block-one d-inline-block" data-aos="fade-up"
                                    data-aos-delay="400"><a href="#"
                                                            className="d-flex align-items-center justify-content-center"><img
                                    src="images/logo/Plogo-14.png" alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div className="blog-section-one pt-100 pb-140 lg-pt-80 lg-pb-80">
                <div className="container">
                    <div className="title-style-one text-center mb-50 lg-mb-20" data-aos="fade-up">
                        <div className="sc-title">RECENT NEWS</div>
                        <h2 className="main-title">Inside Story & Blog</h2>
                    </div>


                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-one tran3s mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_01.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">Data Science</a> - 15 minute
                                        read
                                    </div>
                                    <a  href="#" className="blog-title"><h5>Make more daily productive
                                        working flow like pro.</h5></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <article className="blog-meta-one tran3s mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_02.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">UI/UX Design</a> - 10 minute
                                        read
                                    </div>
                                    <a  href="#" className="blog-title"><h5>Internet of the things and
                                        why it’s becoming important in the century.</h5></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <article className="blog-meta-one tran3s mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_03.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">Marketing</a> - 8 minute read
                                    </div>
                                    <a  href="#" className="blog-title"><h5>The power product and the
                                        quotient rules.</h5></a>
                                </div>

                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-lg-none d-flex" data-aos="fade-up">
                            <article className="blog-meta-one tran3s mt-30">
                                <figure className="post-img m0"><a  href="#"
                                                                   className="w-100 d-block"><img
                                    src="images/blog/blog_img_01.jpg" alt="" className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a  href="#">Data Science</a> - 15 minute
                                        read
                                    </div>
                                    <a  href="#" className="blog-title"><h5>Make more daily productive
                                        working flow like pro.</h5></a>
                                </div>

                            </article>
                        </div>
                    </div>
                </div>
            </div>


            <div className="address-section-one pt-130 lg-pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 col-md-9 m-auto">
                            <div className="title-style-one text-center mb-50" data-aos="fade-up">
                                <div className="sc-title-two">Be a pro with us</div>
                                <h2 className="main-title">Get Ready to Started It’s Fast, Free & very easy</h2>
                            </div>

                            <div className="text-center" data-aos="fade-up" data-aos-delay="150"><a
                                 href="#" className="btn-four ripple-btn">Get Started <i
                                className="fas fa-chevron-right"></i></a></div>
                        </div>
                    </div>

                    <div className="inner-content" data-aos="fade-up" data-aos-delay="100">
                        <div className="row g-0">
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex border-right">
                                    <div className="icon"><img src="images/icon/icon_06.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Our Address</h4>
                                        <p>1012 Pebda Parkway, Mirpur 2 <br/>Dhaka, Bangladesh</p>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex">
                                    <div className="icon"><img src="images/icon/icon_07.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Contact Info</h4>
                                        <p>Open a chat or give us call at <br/><a href="#">310.841.5500</a></p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <img src="images/assets/bg_05.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_01.svg" alt="" className="shapes shape-two"/>
                <img src="images/shape/shape_02.svg" alt="" className="shapes shape-three"/>
                <img src="images/shape/shape_02.svg" alt="" className="shapes shape-four"/>
                <img src="images/shape/shape_03.svg" alt="" className="shapes shape-five"/>
            </div>



            <div className="footer-style-one theme-basic-footer">
                <div className="container">
                    <div className="inner-wrapper">
                        <div className="row">
                            <div className="col-lg-3 footer-intro mb-40">
                                <div className="logo"><a  href="#"><img src="images/logo/logo_01.png" alt=""
                                                                                width="130"/></a></div>
                                <p>We will assist you in becoming more successful.</p>
                                <ul className="d-flex social-icon style-none">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Links</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a  href="#">Home</a></li>
                                    <li><a  href="#">Pricing</a></li>
                                    <li><a  href="#">About us</a></li>
                                    <li><a  href="#">Service</a></li>
                                    <li><a  href="#">Blog</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 mb-30">
                                <h5 className="footer-title">Legal</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a  href="#">Terms of use</a></li>
                                    <li><a  href="#">Terms & conditions</a></li>
                                    <li><a  href="#">Privacy policy</a></li>
                                    <li><a  href="#">Cookie policy</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-5 col-lg-4 col-md-6 mb-30">
                                <div className="newsletter ps-xl-5">
                                    <h5 className="footer-title">Subscribe </h5>
                                    <p>Join over <span>68,000</span> people getting our emails</p>
                                    <form action="#">
                                        <input type="email" placeholder="Enter your email"/>
                                        <button>Sign Up</button>
                                    </form>
                                    <div className="info">We only send interesting and relevant emails.</div>
                                </div>

                            </div>
                        </div>

                        <div className="bottom-footer">
                            <div className="d-lg-flex justify-content-between align-items-center">
                                <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                    <li><a  href="#">Privacy &amp; Terms.</a></li>
                                    <li><a  href="#">FAQ</a></li>
                                    <li><a  href="#">Contact Us</a></li>
                                </ul>
                                <p className="copyright text-center order-lg-0 pb-15">Copyright @2022 sinco inc.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <button className="scroll-top">
                <i className="bi bi-arrow-up-short"></i>
            </button>


        </div>
    );
};

export default MachineLearning;