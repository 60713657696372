import React from 'react';

const CookiePolicy = () => {
    return (
        <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-7 ms-auto">
                        <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                            <div className="title-style-three">
                                <div className="sc-title">Cookie Policy</div>
                            </div>
                            <p className="pt-20 pb-15">At SNSTEC, we are committed to ensuring transparency and
                                compliance with data protection regulations. This Cookie Policy explains how we use
                                cookies and similar technologies on our website.</p>
                            <h2 className="main-title">1. What Are Cookies?</h2>
                            <p className="pt-20 pb-15">Cookies are small text files that are stored on your device when
                                you visit a website. They help us enhance your user experience by remembering your
                                preferences and enabling certain functionalities.</p>
                            <h2 className="main-title">2. How We Use Cookies</h2>
                            <p className="pt-20 pb-15">
                                We use cookies to:
                                <ul>
                                    <li>Improve website functionality and performance</li>
                                    <li>Analyze site usage to enhance user experience</li>
                                    <li>Provide personalized content and advertisements</li>
                                </ul>
                            </p>
                            <h2 className="main-title">3. Types of Cookies We Use</h2>
                            <p className="pt-20 pb-15">
                                <ul>
                                    <li>Necessary Cookies: Essential for the website to function properly.</li>
                                    <li>Performance Cookies: Help us understand how visitors interact with our site.
                                    </li>
                                    <li>Functional Cookies: Enable enhanced functionality and personalization.</li>
                                    <li>Targeting Cookies: Used to deliver relevant advertisements to you.</li>
                                </ul>
                            </p>
                            <h2 className="main-title">4. Managing Your Cookie Preferences</h2>
                            <p className="pt-20 pb-15">
                                You can manage your cookie preferences through your browser settings. You can choose to
                                block or delete cookies, but this may affect your experience on our website.
                            </p>
                            <h2 className="main-title">5. Third-Party Cookies</h2>
                            <p className="pt-20 pb-15">
                                We may use third-party cookies for analytics and advertising purposes. These cookies are
                                governed by the respective third-party privacy policies.
                            </p>
                            <h2 className="main-title">6. Changes to This Cookie Policy</h2>
                            <p className="pt-20 pb-15">
                                We may update this Cookie Policy from time to time. Any changes will be posted on this
                                page with an updated effective date.
                            </p>
                            <h2 className="main-title">7. Contact Us</h2>
                            <p className="pt-20 pb-15">
                                If you have any questions about our Cookie Policy, please contact us at cookie@snstec.com or 35967496.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy;