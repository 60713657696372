import React, {useCallback, useMemo} from 'react';

const WorkProcess = () => {
    return (
        <div className="fancy-feature-nineteen position-relative pt-130 lg-pt-80">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-5 col-lg-6 col-md-7">
                        <div className="block-style-thirteen" data-aos="fade-right">
                            <div className="title-style-three pb-15">
                                <div className="sc-title">WORK PROCESS</div>
                                <h2 className="main-title">How SNSTEC <span>process</span> & works.</h2>
                            </div>
                            <ul className="style-none list-item">
                                <li data-aos="fade-up">
                                    <div className="numb tran3s">1</div>
                                    <h6>Frame the Problem</h6>
                                    <span>Businesses need efficient AI solutions for accurate credit analysis, fraud detection, and customer insights.</span>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="50">
                                    <div className="numb tran3s">2</div>
                                    <h6>Collect the Data</h6>
                                    <span>Gather comprehensive credit data using AI for accurate analysis and insightful financial decision-making.</span>
                                </li>
                                <li data-aos="fade-up" data-aos-delay="100">
                                    <div className="numb tran3s">3</div>
                                    <h6>Process the Data</h6>
                                    <span>Utilize AI to transform raw credit data into actionable insights for informed financial decisions.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="illustration-holder" data-aos="fade-left">
                <img src="images/assets/ils_15.svg" alt="" className="w-100 main-illustration"/>
                <img src="images/assets/ils_15_1.svg" alt="" className="shapes shape-one"/>
                <img src="images/assets/ils_15_2.svg" alt="" className="shapes shape-two"/>
                <img src="images/assets/ils_15_3.svg" alt="" className="shapes shape-three"/>
                <img src="images/assets/ils_15_4.svg" alt="" className="shapes shape-four"/>
                <img src="images/assets/ils_15_5.svg" alt="" className="shapes shape-five" data-aos="fade-down"
                     data-aos-delay="200" data-aos-duration="2000"/>
                <img src="images/assets/ils_15_6.svg" alt="" className="shapes shape-six" data-aos="fade-down"
                     data-aos-delay="100" data-aos-duration="2000"/>
                <img src="images/assets/ils_15_7.svg" alt="" className="shapes shape-seven" data-aos="fade-down"
                     data-aos-duration="2000"/>
            </div>
            <div className="shapes oval-one"></div>
            <div className="shapes oval-two"></div>
            <img src="images/shape/shape_35.svg" alt="" className="shapes bg-shape"/>
        </div>
    );
}

export default WorkProcess;