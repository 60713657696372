import React from 'react';
import {Link} from "react-router-dom";
const Contact = () => {
    return (
        <div>
            <div className="theme-inner-banner">
                <div className="container">
                    <h2 className="intro-title text-center">Get in Touch</h2>
                    <ul className="page-breadcrumb style-none d-flex justify-content-center">
                        <li><Link to='/'>Home</Link></li>
                        <li className="current-page">Contact</li>
                    </ul>
                </div>
                <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one"/>
                <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two"/>
            </div>

            <div className="contact-section-one mb-170 lg-mb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="address-block-two text-center mb-40 sm-mb-20">
                                <div className="icon d-flex align-items-center justify-content-center m-auto"><img
                                    src="images/icon/icon_17.svg" alt=""/></div>
                                <h5 className="title">Our Address</h5>
                                <p>21/F, Wanchai Central Building, 89 Lockhart Road, <br/>Wanchai, Hong Kong</p>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="address-block-two text-center mb-40 sm-mb-20">
                                <div className="icon d-flex align-items-center justify-content-center m-auto"><img
                                    src="images/icon/icon_18.svg" alt=""/></div>
                                <h5 className="title">Contact Info</h5>
                                <p>Give us call at <br/><a href="tel:852.35967496" className="call">+852 3596 7496</a></p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-100 lg-mt-70">
                    <div className="container">
                        <div className="row gx-xxl-5">
                            <div className="col-lg-6 d-flex order-lg-last">
                                <div className="form-style-one">
                                    <h3 className="form-title pb-40 lg-pb-20">Send Message.</h3>
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div className="messages"></div>
                                        <div className="row controls">
                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <label>Name*</label>
                                                    <input type="text" placeholder="Rashed Kabir" name="name" required
                                                            data-error="Name is required."/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <label>Email*</label>
                                                    <input type="email" placeholder="rshdkabir@gmail.com" name="email" required
                                                           data-error="Valid email is required."/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <textarea placeholder="Your message*" name="message" required
                                                              data-error="Please,leave us a message."></textarea>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn-eight ripple-btn">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div className="col-lg-6 d-flex order-lg-first">
                                <div className="map-area-one mt-10 me-lg-4 md-mt-50">
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe  title={'map'}  className="gmap_iframe" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Wanchai Central Building, 89 Lockhart Road, Wanchai, Hong Kong.&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;