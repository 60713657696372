import React from 'react';

const Terms = () => {
    return (
        <div className="fancy-feature-twentyOne mt-200 pb-100 lg-mt-120 lg-pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-7 ms-auto">
                        <div className="block-style-five ps-xxl-5" data-aos="fade-left">
                            <div className="title-style-three">
                                <div className="sc-title">Terms of Use</div>
                            </div>
                            <p className="pt-20 pb-15">Welcome to SNSTEC. By accessing or using our website, you agree
                                to comply with and be bound by the following terms and conditions. Please read them
                                carefully.</p>
                            <h2 className="main-title">1. Acceptance of Terms</h2>
                            <p className="pt-20 pb-15">By using our site, you confirm that you accept these terms of use
                                and agree to comply with them. If you do not agree to these terms, you must not use our
                                site.</p>
                            <h2 className="main-title">2. User Representations</h2>
                            <p className="pt-20 pb-15">By using the site, you represent and warrant that:
                                <ul>
                                    <li>All registration information you submit is true, accurate, current, and
                                        complete.
                                    </li>
                                    <li>You will maintain the accuracy of such information and promptly update it as
                                        necessary.
                                    </li>
                                    <li>You have the legal capacity and agree to comply with these terms.
                                    </li>
                                    <li>You will not access the site through automated or non-human means.
                                    </li>
                                    <li>You will not use the site for any illegal or unauthorized purpose.
                                    </li>
                                </ul>
                            </p>
                            <h2 className="main-title">3. Prohibited Activities</h2>
                            <p className="pt-20 pb-15">You may not access or use the site for any purpose other than
                                that for which we make the site available. Prohibited activities include, but are not
                                limited to, unauthorized commercial use, spamming, and reverse engineering.</p>
                            <h2 className="main-title">4. User-Generated Content</h2>
                            <p className="pt-20 pb-15">The site may allow you to create, submit, post, display,
                                transmit, perform, publish, distribute, or broadcast content and materials. You retain
                                full ownership of your contributions, but you grant us a license to use them.</p>
                            <h2 className="main-title">5. Termination of Use</h2>
                            <p className="pt-20 pb-15">We reserve the right to suspend or terminate your account and
                                refuse any and all current or future use of the site if you violate these terms.</p>
                            <h2 className="main-title">6. Limitation of Liability</h2>
                            <p className="pt-20 pb-15">Our liability is limited to the maximum extent permitted by law.
                                We are not liable for any indirect, incidental, or consequential damages arising from
                                your use of the site.</p>
                            <h2 className="main-title">7. Governing Law</h2>
                            <p className="pt-20 pb-15">These terms are governed by and construed in accordance with the
                                laws of Hong Kong S.A.R., and you irrevocably submit to the exclusive jurisdiction of
                                the courts in that location.</p>
                            <h2 className="main-title">8. Changes to Terms</h2>
                            <p className="pt-20 pb-15">We may revise these terms at any time by amending this page.
                                Please check this page from time to time to take notice of any changes we made, as they
                                are binding on you.</p>
                            <p className="pt-20 pb-15">For any questions or concerns about these terms, please contact us at terms@snstec.com or 3596 7496</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Terms;