import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-style-four space-fix-one theme-basic-footer">
            <div className="container">
                <div className="inner-wrapper">
                    <div className="row">
                        <div className="col-lg-4 footer-intro mb-40">
                            <div className="logo">
                                <img src="images/logo/logo_01.png" alt="" width="130"/>
                            </div>
                            <p>SNSTEC is a financial technology company based in Hong Kong, specializing in AI and
                                machine learning solutions for the finance industry.</p>
                        </div>
                        <div className="col-lg-2 col-sm-4 ms-auto mb-30">
                            <h5 className="footer-title">Links</h5>
                            <ul className="footer-nav-link style-none">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/services">Services</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-4 mb-30">
                            <h5 className="footer-title">Services</h5>
                            <ul className="footer-nav-link style-none">
                                <li>Artificial Intelligence</li>
                                <li>Data Analytics</li>
                                <li>Data Visualization</li>
                                <li>Deep Learning</li>
                                <li>Statistical Modeling</li>
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-4 mb-30">
                            <h5 className="footer-title">Legal</h5>
                            <ul className="footer-nav-link style-none">
                                <li><Link to="/terms">Terms of use</Link></li>
                                <li><Link to="/tc">Terms & conditions</Link></li>
                                <li><Link to="/privacypolicy">Privacy policy</Link></li>
                                <li><Link to="/cookiepolicy">Cookie policy</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <div className="d-lg-flex justify-content-between align-items-center">
                            <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                <Link to="/privacy">Privacy &amp; Terms</Link>
                                <Link to="/contact">Contact Us</Link>
                            </ul>
                            <p className="copyright text-center order-lg-0 pb-15">Copyright @2024 SNSTEC. All right
                                reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
