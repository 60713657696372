import React  from 'react';
import { Link } from 'react-router-dom';

const Home = () => {

    return (
        <div>
            <div className="fancy-feature-two position-relative mt-140 lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-5 col-lg-6 col-md-7 ms-auto">
                            <div className="block-style-two" data-aos="fade-left">
                                <div className="title-style-one">
                                    <div className="sc-title-four">WHO WE ARE</div>
                                    <h2 className="main-title">AI innovations for smarter, secure, and insightful financial solutions. </h2>
                                </div>
                                <p className="pt-10 pb-20 lg-pb-10">AI technology provides optimal business solutions, and we are here to help you achieve your goals</p>
                                <Link to="/contact" className="btn-one mt-30">Contact us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="illustration-holder-two sm-mt-40">
                    <img src="images/assets/ils_05.svg" alt="" className="main-illustration w-100"/>
                    <img src="images/assets/ils_05_1.svg" alt="" className="shapes shape-one"/>
                    <img src="images/assets/ils_05_2.svg" alt="" className="shapes shape-two" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="100" data-aos-duration="2000"/>
                    <img src="images/assets/ils_05_3.svg" alt="" className="shapes shape-three" data-aos="fade-up"
                         data-aos-anchor=".fancy-feature-two" data-aos-delay="150" data-aos-duration="2000"/>
                    <img src="images/assets/ils_05_4.svg" alt="" className="shapes shape-four"/>
                    <img src="images/assets/ils_05_5.svg" alt="" className="shapes shape-five"/>
                </div>
            </div>


            <div className="fancy-feature-twentyTwo mt-150 lg-mt-60">
                <div className="container">
                    <div className="row gx-xxl-5">
                        <div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <div className="block-style-fourteen mt-35">
                                <h5>Our Mission</h5>
                                <p>Our mission is to leverage AI and ML technologies to deliver transformative solutions that improve efficiency, accuracy, and customer satisfaction in the financial sector. We aim to support financial institutions in navigating the complexities of modern finance through intelligent automation and predictive analytics.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <div className="block-style-fourteen mt-35">
                                <h5>Our Vision</h5>
                                <p>To become a leading provider of AI-driven financial solutions in Asia, empowering financial institutions with the tools needed to thrive in a rapidly evolving digital landscape.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="counter-section-one">
                <div className="inner-container bg-color style-two rounded-0 w-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-6" data-aos="fade-up">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">20</span>mil</div>
                                    <p>Human labor hours saved <br/> with help of AI</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count"><span className="counter">1.3</span>b+</div>
                                    <p>Generated revenue by <br/>AI Solutions</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="counter-block-one text-center mb-20">
                                    <div className="main-count">$<span className="counter">15</span>mil+</div>
                                    <p>Saved operational costs <br/>due to AI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="images/shape/shape_40.svg" alt="" className="shapes shape-three"/>
                    <img src="images/shape/shape_41.svg" alt="" className="shapes shape-four"/>
                </div>
            </div>

            <div className="address-section-one">
                <div className="container">
                    <div className="inner-content bg-white" data-aos="fade-up" data-aos-delay="100">
                        <div className="row g-0">
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex border-right">
                                    <div className="icon"><img src="images/icon/icon_06.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Our Address</h4>
                                        <p>21/F, Wanchai Central Building, 89 Lockhart Road,<br/>Wanchai, Hong Kong</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex">
                                <div className="address-block-one d-flex">
                                    <div className="icon"><img src="images/icon/icon_07.svg" alt=""/></div>
                                    <div className="text-meta">
                                        <h4 className="title">Contact Info</h4>
                                        <p>Give us call at <br/><a href="#">+852 3596 7496</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;